import "../scss/catalog.scss";
import {useTranslation} from "react-i18next";
import SteamImage from '../images/components/steam.png';
import SteamInput from "../components/steamInput/SteamInput";
import {useState} from "react";
import {Bank, Visa, Kaspi, SBP} from "../images/payment";
import useMainService from "../services/MainService";
import PaymentItem from "../components/paymentItem/PaymentItems";
import Button from "../components/button/Button";
import {SteamInfo} from "../images/system";
import CategoriesList from "../components/categoriesList/CategoriesList";
import {RightLow} from "../images/arrows";
import Path from "../components/path/Path";


const Steam = () => {
    const {t} = useTranslation();
    const pathValues = ["Главная", "Steam"];
    const {getKassa24Link} = useMainService();
    const [name, setName] = useState('');
    const [sum, setSum] = useState(0);
    const [sumError, setSumError] = useState(false);
    const [email, setEmail] = useState(' ');
    const [emailError, setEmailError] = useState(false);
    const [current, setCurrent] = useState(-1);
    const [isLoginInfoVisible, setIsLoginInfoVisible] = useState(false);

    const payments = [
        {
            src: Visa,
            title: (
                <span>
                    {"KZ"}
                    {/*<img*/}
                    {/*    src={Visa}*/}
                    {/*    alt="Visa"*/}
                    {/*    style={{*/}
                    {/*        width: "80px", */}
                    {/*        marginLeft: "10px", */}
                    {/*        display: "inline-block", */}
                    {/*        verticalAlign: 'middle'}}*/}
                    {/*/>*/}
                </span>
            ),
            action: async () => {
                let basketId = localStorage.getItem('basketId');
                if (basketId) {
                    return getKassa24Link(basketId)
                        .then(data => {
                            if (data.link_url) {
                                return data.link_url; // Возвращаем ссылку
                            } else {
                                throw new Error(t("payment-text3"));
                            }
                        });
                } else {
                    throw new Error('Не удалось получить идентификатор корзины.');
                }
            }
        },
        {
            src: Kaspi,
            title: (
                <span>
                    {"KZ"}
                    {/*<img*/}
                    {/*    src={''}*/}
                    {/*    alt="Visa"*/}
                    {/*    style={{*/}
                    {/*        width: "80px", */}
                    {/*        marginLeft: "10px", */}
                    {/*        display: "inline-block", */}
                    {/*        verticalAlign: 'middle'}}*/}
                    {/*/>*/}
                </span>
            ),
            action: () => {
                console.log("Оплата через другой банк"); // срабатывает
            }
        },
        {
            src: SBP,
            title: (
                <span>
                    {"RU"}
                    {/*<img*/}
                    {/*    src={''}*/}
                    {/*    alt="Visa"*/}
                    {/*    style={{*/}
                    {/*        width: "80px", */}
                    {/*        marginLeft: "10px", */}
                    {/*        display: "inline-block", */}
                    {/*        verticalAlign: 'middle'}}*/}
                    {/*/>*/}
                </span>
            ),
            action: () => {
                console.log("Оплата через другой банк"); // срабатывает
            }
        }
    ];

    const handleSumChange = (text) => {
        const numericValue = text.replace(/\D/g, '');
        setSum(numericValue);
        setSumError(numericValue === '');
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleEmailChange = (text) => {
        setEmail(text);
        setEmailError(!validateEmail(text));
    };

    const loginInfoIsActive = (bool) => {
        setIsLoginInfoVisible(bool);
    };


    return (
        <main className="main">
            <CategoriesList />
            <Path values={pathValues} icon={RightLow} />
            <div className="main__container">
                <div className="title">{t('steam-link')}</div>
            </div>
            <div className="steam-container">
                <div className="steam-info-wrapper">
                    <div>
                        <img src={SteamImage} alt="steam"/>
                    </div>
                    <div>
                        <SteamInput
                            theme={
                                <div
                                    onMouseEnter={() => loginInfoIsActive(true)}
                                    onMouseLeave={() => loginInfoIsActive(false)}
                                    className="steam-payment-info"
                                >
                                    {isLoginInfoVisible && (
                                        <div className="steam-info-popup" style={{padding:"0px"}}>
                                            <img src={SteamInfo} alt="steam login info"/>
                                        </div>
                                    )}
                                    {t("steam-login")}❔
                                </div>
                            }
                            check={text => text !== ""}
                            setText={setName}
                            placeHolder={t("steam-login-placeholder")}
                        />
                    </div>
                    <div>
                        <SteamInput
                            theme={t("steam-sum")}
                            // error={sumError ? t("steam-sum-error") : ""}
                            check={() => !sumError}
                            setText={handleSumChange}
                            placeHolder={t("steam-sum-placeholder")}
                            inputType="number"
                        />
                    </div>
                    <div>
                        {/*<p>{t("steam-email-info")}</p>*/}
                        <SteamInput
                            theme={t("steam-email")}
                            // error={emailError ? t("steam-email-error") : ""}
                            check={() => !emailError}
                            setText={handleEmailChange}
                            placeHolder={t("steam-email-placeholder")}
                        />
                    </div>
                </div>
                <div className="steam-payment-wrapper">
                    <div>
                        <div>
                            <p>Метод пополнения</p>
                        </div>
                        <div className="payments">
                            {payments.map((item, index) => (
                                <PaymentItem
                                    onClick={() => setCurrent(index)}
                                    key={index}
                                    src={item.src}
                                    title={item.title}
                                    text={item.text}
                                    isSelected={current === index}
                                />
                            ))}
                        </div>
                    </div>
                    <Button
                        text={t("to-payment")}
                        extra={"btn-medium"}
                    />
                </div>
            </div>
        </main>
    );
};

export default Steam;