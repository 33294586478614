import {useContext, useEffect, useMemo, useState} from "react";
import Search from "../components/header/search/Search";
import useMainService from "../services/MainService";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";
import FilterSort from "../components/filterSort/FilterSort";
import {AuthContext} from "../providers/AuthProvider";
import {useNavigate} from "react-router-dom";

const History = () => {
    const { getAllBaskets } = useMainService()
    const [baskets, setBaskets] = useState([]);
    const [sortState, setSortState] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc'); // Направление сортировки (asc или desc)
    const [dateRange, setDateRange] = useState({ from: null, to: null }); // Диапазон дат
    const {t} = useTranslation();
    const { isAuth } = useContext(AuthContext);
    const navigate = useNavigate();

    // Получаем данные из Redux
    const {currencies,activeCurrency} = useSelector(state => state.cart);

    // Мемоизируем вычисления валюты и её символа
    const currencyAmount = useMemo(() => {
        const currencyExists = activeCurrency && currencies[activeCurrency.index];
        return currencyExists ? currencies[activeCurrency.index].amount : 1;
    }, [activeCurrency, currencies]);

    const currencySymbol = useMemo(() => {
        return activeCurrency ? activeCurrency.symbol : '₸';
    }, [activeCurrency]);

    // Проверка авторизации и редирект, если пользователь не авторизован
    useEffect(() => {
        if (!isAuth || localStorage.getItem('token') === null) {
            navigate('/'); // Перенаправление на главную страницу
        }
    }, [isAuth, navigate]);

    useEffect(() => {
        const fetchBaskets = async () => {
            try {
                const data = await getAllBaskets();
                setBaskets(data);
            } catch (err) {
                console.error('Error fetching baskets:', err);
            }
        };
        fetchBaskets();
    }, []);

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleString('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const basketAmount=(amount)=>(amount * currencyAmount).toFixed(2)

    // Фильтруем заказы только со статусом 3 и по дате (если задан диапазон)
    const filteredBaskets = useMemo(() => {
        return baskets.filter(basket => {
            const basketDate = new Date(basket.createDate);
            const inRange = (!dateRange.from || basketDate >= dateRange.from) &&
                (!dateRange.to || basketDate <= dateRange.to);
            return basket.statusId === 3 && inRange;
        });
    }, [baskets, dateRange]);

    // Логика сортировки
    const sortedBaskets = useMemo(() => {
        let sorted = [...filteredBaskets];
        if (sortState === 'date') {
            // Сортировка по дате
            sorted.sort((a, b) => sortDirection === 'asc'
                ? new Date(a.createDate) - new Date(b.createDate)
                : new Date(b.createDate) - new Date(a.createDate));
        } else if (sortState === 'amount') {
            // Сортировка по сумме
            sorted.sort((a, b) => sortDirection === 'asc' ? a.amount - b.amount : b.amount - a.amount);
        }
        return sorted;
    }, [filteredBaskets, sortState, sortDirection]);

    return (
        <main className="main">
            <div className="information">
                <div className="information__row">
                    <div className="title">{t('history-buy')}</div>
                    <div className="flow">
                        <Search extra={"full"} />

                        <div className="filter">
                            <div className="filter__row">
                                <FilterSort
                                    classes={"filter__sort"}
                                    values={[
                                        { value: t("by-date"), state: "date" },
                                        { value: t("by-price"), state: "amount" }
                                    ]}
                                    setSortState={setSortState}
                                    sortDirection={sortDirection}
                                    setSortDirection={setSortDirection}
                                    setDateRange={setDateRange}
                                />
                            </div>
                        </div>

                        <div className="table third">
                            <div className="table__head">
                                <div className="table__elem">{t("cart-status")}</div>
                                <div className="table__elem">{t("date-order")}</div>
                                <div className="table__elem">{t("amount")}</div>
                            </div>
                            {sortedBaskets.map((basket)=> {
                                return <div key={basket.id} className="table__row">
                                    <div className="table__elem">{t("payed")}</div>
                                    <div className="table__elem">{formatDate(basket.createDate)}</div>
                                    <div className="table__elem">{basketAmount(basket.amount)} {currencySymbol}</div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default History;