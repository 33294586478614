import {useId} from "react";

const SteamInput = ({
                        theme,
                        extraClass,
                        error,
                        correct,
                        placeHolder,
                        text,
                        setText,
                        inputRef,
                        onKeyDown,
                        inputType = "text"
                    }) => {

    const inputId = useId();

    const handleInputChange = (e) => {
        let value = e.target.value;
        if (inputType === "number") {
            value = value.replace(/\D/g, ''); // Удаляем любые символы, кроме цифр
        }
        setText(value);
    };



    return (
        <div className={"fields__item" + (correct ? "" : "") + " " + (extraClass ? extraClass : "")}>
            <div className={"fields__input"}>
                <input
                    ref={inputRef}
                    className={text !== "" ? "focus" : ""}
                    value={text}
                    type={inputType === "number" ? "text" : inputType}
                    onInput={handleInputChange}
                    required
                    id={inputId}
                    placeholder={placeHolder ? placeHolder : ''}
                    onKeyDown={onKeyDown}/>

                <label htmlFor={inputId}>
                    <p>
                        {theme}
                    </p>
                </label>
            </div>

            {!correct && error && (
                <div className="fields__error">
                    <p>{error}</p>
                </div>
            )}

        </div>
    );
}

export default SteamInput;
