import { Link } from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect} from "react";

const NavigationItem = (props) => {
    const { src, to, srcHover, isCart, alt, onClick } = props;
    // Получаем товары из состояния корзины
    const cartItems = useSelector((state) => state.cart.items);

    // Вычисляем общее количество товаров в корзине
    const totalItemCount = cartItems.reduce((acc, item) => acc + item.count, 0);

    useEffect(() => {
        console.log('Cart items updated:', cartItems);
    }, [cartItems]);

    const notification = totalItemCount > 0 && isCart ? <Notification number={totalItemCount} /> : null;

    const element = to ? <Link to={to ? to : ""} className="navigation__item">
        <div className="navigation__icon">
            <img className="navigation__icon-normal" src={src} alt={alt} />
            <img className="navigation__icon-hover" src={srcHover} alt={alt} />
        </div>
        {notification}
    </Link> : <div className="navigation__item" onClick={onClick}>
        <div className="navigation__icon">
            <img className="navigation__icon-normal" src={src} alt={alt} />
            <img className="navigation__icon-hover" src={srcHover} alt={alt} />
        </div>
        {notification}
    </div>;
    return (
        <>
            {element}
        </>
    );
}

const Notification = ({ number }) => {
    return (
        <div className="navigation__notification notification">
            {number}
        </div>
    )
}

export default NavigationItem;