import Button from "../components/button/Button";
import Search from "../components/header/search/Search";
import { Brom } from "../images/system";
import FilterSort from "../components/filterSort/FilterSort";
import GameForm from "../components/gameForm/GameForm";
import "../scss/catalog.scss";
import CategoriesList from "../components/categoriesList/CategoriesList";
import useMainService from "../services/MainService";
import { useEffect, useMemo, useState } from "react";
import EmptyPage from "../components/emptyPage/EmptyPage";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Favourites = () => {
    const { loading, error, getFavourites, deleteFavourite } = useMainService();
    const navigate = useNavigate();
    const [games, setGames] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        getFavourites()
            .then(data => { favouritesLoaded(data)});
    }, []);

    const favouritesLoaded = (data) => {
        if (data) {
            setGames(data);
        }
    }

    const children = useMemo(() => games.length > 0 ?
        <View games={games} deleteFavourite={deleteFavourite} navigate={navigate} /> :
        <EmptyPage mainText={t("empty-fav")} text={t('ques-add')} icon={"♡"} />, [games]);

    return (
        <>
            <main className="main">
                {!(loading && error) ? children : null}
            </main>
        </>
    );
}

const View = (props) => {
    const { games, deleteFavourite, navigate } = props;
    const { t } = useTranslation();

    const element = useMemo(() => (games ? games.map((item, index) =>
    (<GameForm
        key={index}
        id={item.game.id}
        name={item.game.name}
        src={item.game.images.imagePaths[0]}
        extraClass={"videogames__item"}
        indicators={[]} />)) : null), [games]);

    return (
        <>
            <CategoriesList />

            <div className="main__container">
                <div className="filter">
                    <div className="filter__row">
                        <div className="filter__title title">{t('favourites')}</div>
                        <Button onClick={() => {
                            games.map((item) => {
                                deleteFavourite(item.id)
                                    .then();
                            })
                            navigate("/favourites");
                        }} text={t("clear-favorites")} src={Brom} extra={"btn-strong"} />
                    </div>
                    <div className="filter__row">
                        <div className="filter__content">
                            <div className="filter__text">{t('favourite-count')} 6</div>
                            <Search extra={"filter__search"} />
                        </div>
                        <FilterSort classes={"filter__sort"} />
                    </div>
                </div>
                <div className="sides">
                    <div className="sides__row">
                        <div className="sides__container">
                            <div className="videogames">
                                <div className="videogames__row">
                                    {element}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Favourites;