import { useNavigate } from "react-router-dom";
import Button from "../button/Button";
import {useTranslation} from "react-i18next";

const Profile = (props) => {
    const {src, fullName} = props;
    const nav = useNavigate();
    const {t} = useTranslation();
    
    return (
        <div className="profile">
            <div className="profile__ava">
                <div className="profile__img">
                    <img src={src} alt="user avatar image" />
                </div>
            </div>
            <div className="profile__info">
                <div className="profile__title">{fullName}</div>
                <div className="profile__text"><img src="https://emojio.ru/images/apple-m/1f1f0-1f1ff.png" alt="kz" />
                    Kazakhstan, Almaty </div>
                <div className="profile__btn">
                    <Button onClick={() => {nav("/edit-profile")}} text={t("edit-button")} extra={"btn-strong"} />
                </div>
            </div>
        </div>
    );
}

export default Profile;