import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

const NavbarItem = (props) => {
    const { to, src, srcHover, alt, text, key, isMouseDown } = props;
    const isNumeric = n => !!Number(n);
    const parentRef = useRef(null);
    const childRef = useRef(null);

    useEffect(() => {
        const func = () => {
            parentRef.current.style.width = (childRef.current.offsetWidth + 24) + 'px';
        }

        func();

        window.addEventListener('resize', func);

        return () => {
            window.removeEventListener('resize', func);
        }
    }, []);

    const handleLink = (e) => {
        if (isMouseDown) {
            e.preventDefault();
        }
    }

    return (
        <NavLink ref={parentRef} key={key} to={to} className={({ isActive }) => (`navbar__item ${isActive ? 'navbar__item-active' : ''}`)}
            onClick={handleLink} draggable="false" >
            <div ref={childRef} className="navbar__info">
                <div className="navbar__icon">
                    <img className="navbar__icon-normal" src={src} alt={alt} draggable="false" />
                    <img className="navbar__icon-hover" src={srcHover} alt={alt} draggable="false" />
                </div>
                <div className="navbar__text">
                    <p>{text}</p>
                </div>
            </div>
            <div className="navbar__line">
            </div>
        </NavLink>
    );
}

export default NavbarItem;