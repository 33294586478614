import { useState } from "react";
import { Entry} from "../../images/system";
import Button from "../button/Button";
import useMainService from "../../services/MainService";
import "../../scss/style.scss";
import LoginModal from "../loginModal/LoginModal";
import RegisterModal from "../registerModal/RegisterModal";
import { useTranslation } from "react-i18next";

const AuthModal = ({ text }) => {
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState(0);
    const { clearError, error, registerUser, getAllCountries } = useMainService();
    const { t } = useTranslation();

    const openReg = () => {
        setModal(1);
    }

    const openLog = () => {
        setModal(0);
    }

    return (
        <>
            {text ? <span onClick={() => { setOpen(true) }}>{text}</span> : <Button onClick={() => { setOpen(true); }} text={t('login')} src={Entry} extra={"btn-strong"} />}
            {modal === 0 ?
                <LoginModal open={open} setOpen={(open) => setOpen(open)} setModal={openReg} />
                : <RegisterModal
                    clearError={clearError}
                    getCountries={getAllCountries}
                    register={registerUser}
                    open={open}
                    setOpen={(open) => setOpen(open)}
                    setModal={openLog} />}
        </>
    )
}

export default AuthModal;