import GameForm from "../components/gameForm/GameForm";
import "../scss/catalog.scss";
import CategoriesList from "../components/categoriesList/CategoriesList";
import useMainService from "../services/MainService";
import { useEffect, useState } from "react";
import { Pubg } from "../images/backgrounds";
import { useTranslation } from "react-i18next";

const Discounts = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const {getRecomendations } = useMainService();
    const {t} = useTranslation();

    useEffect(() => {
        setLoading(true);
        getRecomendations()
            .then(onProductLoad)
            .catch(log => console.log(log));
    }, [])

    const onProductLoad = (prods) => {
        setLoading(false);
    }



    return (
        <>
            <div className="main">
                <CategoriesList />

                <div className="main__container">
                    <div className="filter">
                        <div className="filter__row">
                            <div className="filter__title title">{t("discounts")}</div>
                        </div>
                    </div>
                    <div className="sides">
                        <div className="sides__row">
                            <div className="sides__container">
                                <div className="videogames">
                                    <div className="videogames__row">
                                        {products.map((item, index) =>
                                        (<GameForm
                                            key={item.id}
                                            src={item.images ? item.images.mainframe ? item.images.mainframe : Pubg  : Pubg}
                                            id={item.gameId}
                                            name={item.name}
                                            price={item.price} 
                                            oldPrice={item.priceWithDiscount} 
                                            extraClass={"videogames__item"}
                                            indicators={[]} />))}
                                    </div>
                                </div>
                            </div>
                            <div className="sides__sidebar">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Discounts;