import { useTranslation } from "react-i18next";
import {Visa, Mir} from "../images/payment";


const PaymentInstruction = () => {
    const {t} = useTranslation();

    return (
        <main className="main">
            <div className="information">
                <div className="information__row">
                    <div className="news-title">{t('payment-instruction')}</div>
                    <div class="news-title-2">{t('payment-instruction1')}</div>
                    <div className="main-text">{t('payment-instruction11')}</div>
                    <div className="main-text">{t('payment-instruction12')}</div>
                    <div className="news-title-2">{t('payment-instruction2')}</div>
                    <div className="main-text">{t('payment-instruction21')}</div>
                    <div className="main-text">{t('payment-instruction22')}</div>
                    <div className="news-title-2">{t('payment-instruction3')}</div>
                    <div className="main-text">{t('payment-instruction31')}</div>
                    <div className="main-text">{t('payment-instruction32')}</div>
                    <div className="news-title-2">{t('payment-instruction4')}</div>
                    <div className="main-text">
                        <span>
                            {t('payment-instruction41')}
                            <img
                                src={Visa}
                                alt="Visa"
                                style={{width:"80px", marginLeft:"10px", display:"inline-block", verticalAlign: 'middle'}}/>
                            <img
                                src={Mir}
                                alt="Visa"
                                style={{width:"80px", marginLeft:"10px", display:"inline-block", verticalAlign: 'middle'}}/>

                        </span>
                    </div>
                    <div className="main-text">{t('payment-instruction42')}</div>
                    <div className="news-title-2">{t('payment-instruction5')}</div>
                    <div className="main-text">{t('payment-instruction51')}</div>
                    <div className="main-text">{t('payment-instruction511')}</div>
                    <div className="main-text">{t('payment-instruction512')}</div>
                    <div className="main-text">{t('payment-instruction513')}</div>
                    <div className="main-text">{t('payment-instruction52')}</div>
                    <div className="news-title-2">{t('payment-instruction6')}</div>
                    <div className="main-text">{t('payment-instruction61')}</div>
                    <div className="main-text">{t('payment-instruction62')}</div>
                    <div className="news-title-2">{t('payment-instruction7')}</div>
                    <div className="main-text">{t('payment-instruction71')}</div>
                    <div className="main-text">{t('payment-instruction72')}</div>
                </div>
            </div>
        </main>
    );
}

export default PaymentInstruction;