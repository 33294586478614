import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../css/react-datepicker.css'

const FilterSort = (props) => {
    const [active, setActive] = useState(0);
    const { classes, values, setSortState, setSortDirection, sortDirection, setDateRange } = props;
    const [dateRange, setDateRangeState] = useState([null, null]); // Массив для хранения диапазона дат
    const [startDate, endDate] = dateRange; // Разделяем на начальную и конечную даты

    const { t, i18n } = useTranslation();
    const [items, setItems] = useState([
        { value: t("by-date"), state: "date" },
        { value: t("by-price"), state: "amount" }
    ]);

    useEffect(() => {
        if (setSortState)
            setSortState(active);
    }, [active]);

    useEffect(() => {
        if (values) {
            // Если значения передаются через props, обновляем items
            setItems(values.map(item => ({
                ...item,
                value: t(item.value)
            })));
        }
    }, [values, t]);

    useEffect(() => {
        // Обновлять items при смене языка
        setItems([
            { value: t("by-date"), state: "date" },
            { value: t("by-price"), state: "amount" }
        ]);
    }, [i18n.language, t]);

    // Обработчик изменения сортировки
    const handleSortChange = (state) => {
        if (active === state) {
            // Если выбран тот же тип сортировки, меняем направление
            setSortDirection(prevDirection => (prevDirection === 'asc' ? 'desc' : 'asc'));
        } else {
            // Если выбран новый тип сортировки, сбрасываем направление на 'asc'
            setActive(state);
            setSortState(state);
            setSortDirection('asc');
        }
    };

    // Обработчик изменения диапазона дат
    const handleDateFilter = () => {
        setDateRange({ from: startDate, to: endDate });
    };

    // Функция для сброса выбранных дат
    const clearDates = () => {
        setDateRangeState([null, null]); // Сброс диапазона дат в локальном состоянии
        setDateRange({ from: null, to: null }); // Сброс диапазона дат в родительском компоненте
    };

    return (
        <div className={"sort " + (classes ? classes : "")}>
            {items.map((item, index) => (
                <SortItem
                    key={index}
                    onClick={() => handleSortChange(item.state)}
                    active={item.state === active}
                    text={`${t(item.value)} ${sortDirection === 'asc' && active === item.state ? '▲' : '▼'}`}
                />
            ))}

            {/* Добавление фильтрации по дате */}
            <div className="filter-form__choice">
                <div
                    className="search__input"
                >
                    <DatePicker
                        selectsRange={true} // Включает выбор диапазона
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRangeState(update); // Обновляем локальное состояние
                        }}
                        isClearable={true} // Включаем возможность очистки
                        placeholderText="Select a date range"
                    />
                </div>
                <div
                    className="sort__item"
                    onClick={handleDateFilter}
                >
                    <div className="sort__text">
                        {t("apply-date-filter")}
                    </div>

                </div>
            </div>

        </div>
    );
}

const SortItem = ({ active, text, onClick }) => {
    return (
        <div onClick={onClick} className={"sort__item" + (active ? " active" : "")}>
            <div className="sort__text">
                {text}
            </div>
        </div>
    );
}

export default FilterSort;
