import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const NavBottomItem = (props) => {
    const [indicator, setIndicator] = useState(false);
    const { to, src, srcHover, alt, text, isCart, onClick } = props;

    // Получаем товары из состояния корзины
    const cartItems = useSelector((state) => state.cart.items);

    // Вычисляем общее количество товаров в корзине
    const totalItemCount = cartItems.reduce((acc, item) => acc + item.count, 0);

    useEffect(() => {
        console.log('Cart items updated:', cartItems);
    }, [cartItems]);

    const notification = totalItemCount > 0 && isCart ? <Notification number={totalItemCount} /> : null;

    // Если передан onClick, используем его для обработки клика
    // иначе используем обычный NavLink для навигации
    const element = onClick ? (
        <div className="nav-bottom__item" onClick={onClick}>
            <div className="nav-bottom__content">
                <div className="nav-bottom__icon">
                    <img className="nav-bottom__icon-normal" src={src} alt={alt} />
                    <img className="nav-bottom__icon-active" src={srcHover} alt={alt} />
                </div>
                {notification}
                <div className={"nav-bottom__notif " + (indicator ? "" : "hidden")}>
                    <div className="indicator-dot"></div>
                </div>
            </div>
            <div className="nav-bottom__text">
                {text}
            </div>
        </div>
    ) : (
        <NavLink to={to} className={({ isActive }) => "nav-bottom__item " + (isActive ? "active" : "")}>
            <div className="nav-bottom__content">
                <div className="nav-bottom__icon">
                    <img className="nav-bottom__icon-normal" src={src} alt={alt} />
                    <img className="nav-bottom__icon-active" src={srcHover} alt={alt} />
                </div>
                {notification}
                <div className={"nav-bottom__notif " + (indicator ? "" : "hidden")}>
                    <div className="indicator-dot"></div>
                </div>
            </div>
            <div className="nav-bottom__text">
                {text}
            </div>
        </NavLink>
    );

    return <>{element}</>;
}

const Notification = ({ number }) => {
    return (
        <div className="navigation__notification notification">
            {number}
        </div>
    )
}

export default NavBottomItem;
