import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: JSON.parse(localStorage.getItem('cartItems')) || [], // Восстанавливаем корзину из localStorage при загрузке
    sum: 0,
    currencies: [], // Массив объектов для хранения всех валют
    activeCurrency: JSON.parse(localStorage.getItem('activeCurrency')) || null, // Восстанавливаем валюту из localStorage
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setItems: (state, action) => {
            state.items = action.payload;
        },
        addItem: (state, action) => {
            const itemIndex = state.items.findIndex(item => item.productId === action.payload.productId);
            if (itemIndex >= 0) {
                state.items[itemIndex].count += action.payload.count;
            } else {
                state.items.push({ ...action.payload });
            }
            localStorage.setItem('cartItems', JSON.stringify(state.items));
        },
        removeItem: (state, action) => {
            state.items = state.items.filter(item => item.productId !== action.payload);
            localStorage.setItem('cartItems', JSON.stringify(state.items));
        },
        clearCart: (state) => {
            state.items = []; // Очищаем корзину в Redux
            localStorage.removeItem('cartItems'); // Очищаем корзину в localStorage
        },
        setSum: (state) => {
            if (state.activeCurrency && state.activeCurrency.rate) {
                state.sum = state.items.reduce((acc, item) => acc + (item.price * state.activeCurrency.rate), 0);
            } else {
                state.sum = state.items.reduce((acc, item) => acc + item.price, 0); // Если курс не выбран, просто складываем цены в базовой валюте
            }
        },
        updateItemCount: (state, action) => {
            const { productId, count } = action.payload;
            if (count > 0){
                state.items = state.items.map(item =>
                    item.productId === productId ? { ...item, count: count } : item
                );
            } else {
                // Удаляем товар, если количество стало 0
                state.items = state.items.filter(item => item.productId !== productId);
            }
            localStorage.setItem('cartItems', JSON.stringify(state.items));
        },
        setCurrencies: (state, action) => {
            state.currencies = action.payload; // Устанавливаем все доступные валюты как массив объектов
        },
        setActiveCurrency: (state, action) => {
            state.activeCurrency = {
                symbol: action.payload.symbol,  // Символ валюты (₸, $, ₽)
                index: action.payload.index     // Индекс выбранной валюты
            };
            localStorage.setItem('activeCurrency', JSON.stringify(state.activeCurrency)); // Сохраняем выбранную валюту и индекс
        },
    }
});

export const { setItems, addItem, removeItem, setSum, updateItemCount,setCurrencies, setActiveCurrency, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
