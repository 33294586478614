import React, {useEffect, useState} from 'react';
import ArrowIcon from "../../images/arrows/arrow-slide.svg";
import Menu from '../header/menu/Menu';
import MenuItem from '../header/menu/MenuItem';
import {useDispatch, useSelector} from "react-redux";
import {setActiveCurrency} from "../../features/cart/cartSlice";

const CurrencyMenu = () => {
    const dispatch = useDispatch();
    const currencies = ['₸', '₽', '$'];

    // Получаем активную валюту из Redux
    const activeCurrency = useSelector(state => state.cart.activeCurrency);

    // Устанавливаем индекс активной валюты
    const [active, setActive] = useState(() => {
        const savedCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
        return savedCurrency ? savedCurrency.index : 0; // Если активная валюта сохранена, берем её индекс
    });

    useEffect(() => {
        const savedCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
        if (savedCurrency && currencies.includes(savedCurrency.symbol)) {
            const index = savedCurrency.index;
            if (!activeCurrency || activeCurrency.symbol !== savedCurrency.symbol) {
                setActive(index);
                dispatch(setActiveCurrency(savedCurrency));
            }
        } else {
            // Если данных нет, устанавливаем первую валюту как активную
            if (!activeCurrency || activeCurrency.symbol !== currencies[0]) {
                const defaultCurrency = { symbol: currencies[0], index: 0 };
                localStorage.setItem('activeCurrency', JSON.stringify(defaultCurrency));
                dispatch(setActiveCurrency(defaultCurrency));
                setActive(0);
            }
        }
    }, [currencies, dispatch, activeCurrency]);

    const changeCurrency = (currency, index) => {
        const newCurrency = { symbol: currency, index };
        // Сохраняем выбранную валюту и индекс как объект в localStorage
        localStorage.setItem('activeCurrency', JSON.stringify(newCurrency));

        // Обновляем Redux через dispatch (записываем символ и индекс валюты)
        dispatch(setActiveCurrency(newCurrency));

        // Обновляем состояние компонента
        setActive(index);
    };

    return (
        <div className='lng'>
            <div className="lng__active">
                {currencies[active]}
            </div>
            <div className="navigation__button">
                <img src={ArrowIcon} alt="arrow slide down" />
            </div>
            <div className="lng__body">
                <Menu>
                    {currencies.map((currency, index) => (
                        <MenuItem key={index} text={currency} onClick={() => {
                            changeCurrency(currency, index);
                        }} />
                    ))}
                </Menu>
            </div>
        </div>
    );
};

export default CurrencyMenu;
