import {RemoveItem} from "../../images/system";

const NotificationItem = ({removeItem}) => {
    return (
        <div className="delete-notification-container"
             onClick={removeItem}
        >
            <img className="delete-notification-image" src={RemoveItem} alt="remove-item-image"/>
        </div>
    )
}

export default NotificationItem;