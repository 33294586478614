import { useEffect, useState } from "react";
import CategoriesItem from "../components/categoriesItem/CategoriesItem";
import Path from "../components/path/Path";
import { RightLow } from "../images/arrows";
import useMainService from "../services/MainService";
import CategoriesList from "../components/categoriesList/CategoriesList";
import { useTranslation } from "react-i18next";
import {RotatingLines} from "react-loader-spinner";

const Categories = () => {
    const pathValues = ["Главная", "Категории"];
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState([]);
    const { getAllCategories } = useMainService();
    const {t} = useTranslation();

    useEffect(() => {
        setLoading(true);
        getAllCategories()
            .then(onCategoriesLoad)
            .catch(() => setLoading(false));
    }, []);

    const onCategoriesLoad = (categories) => {
        setLoading(false);
        setCategories(categories);
    }

    return (
        <div className="main">
            <CategoriesList />
            
            <Path values={pathValues} icon={RightLow} />
            <div className="main__container">
                <div className="title">
                    {t("categories")}
                </div>

                {loading ? (  // Показываем спиннер во время загрузки
                    <div style={{margin: "0 auto"}}>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                ) : (
                    <section className="categories grid">
                        {categories.map((item, index) => (
                            <CategoriesItem
                                key={index}
                                name={item.name}
                                to={`/catalog?categoryname=${item.name}`}
                                icon={item.id - 1} />
                        ))}
                    </section>
                )}
            </div>
        </div>
    )
}

export default Categories;