import { useMemo } from "react";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

const EmptyPage = (props) => {
    const { mainText, text, icon, src } = props;
    const { t } = useTranslation();

    const mainIcon = useMemo(() => (icon ? icon : src ? <img src={src} alt={"empty"} /> : '✗'), [icon, src]);

    return (
        <section className="empty">
            <div className="empty__row">
                <div className="empty__icon">
                    {mainIcon}
                </div>

                <div className="empty__content">
                    <div className="empty__title">{mainText}</div>
                    <div className="empty__text">{text}</div>
                </div>

                <div className="empty__btns">
                    <Link to={'/'} className="btn btn-strong">
                        <p>{t("to-main-page")}</p>
                    </Link>
                    <Link to={"/catalog"} className="btn btn-strong">
                        <p>{t("games-link")}</p>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default EmptyPage;