import {Routes, Route, HashRouter, BrowserRouter} from "react-router-dom";

import Header from "../header/Header";
import Footer from "../footer/Footer";

import "../../scss/style.scss";
import { About, Home, Cart, Catalog, Categories, EditProfile, Favourites, GameInfo, History, NewsInfo, News, Payment, Policy, ProfilePage, Support, MenuProfile, Subscribes, PaymentTalk, Discounts, CatalogGames, UserAgreement } from "../../pages";
import { BasketProvider } from "../../providers/BasketProvider";
import Disclaimer from "../disclaimer/Disclaimer";
import { PopupProvider } from "../../providers/PopupProvider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AuthProvider from "../../providers/AuthProvider";
import {CartProvider} from "../../providers/CartProvider";
import PublicOffer from "../../pages/PublicOffer";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import PaymentInstruction from "../../pages/PaymentInstruction";
import {useDispatch} from "react-redux";
import useMainService from "../../services/MainService";
import {useEffect} from "react";
import {setActiveCurrency, setCurrencies} from "../../features/cart/cartSlice";
import Steam from "../../pages/Steam";


const App = () => {
    const dispatch = useDispatch();
    const { getCurrency } = useMainService();


    // Функция для получения курсов валют с сервера
    const fetchCurrencies = async () => {
        try {
            const data = await getCurrency();
            if (data && Array.isArray(data)) {
                dispatch(setCurrencies(data)); // Обновляем курсы валют в Redux
                sessionStorage.setItem('currencies', JSON.stringify(data)); // Сохраняем курсы в localStorage

                // Устанавливаем первую валюту по умолчанию, если activeCurrency еще не установлена
                const savedActiveCurrency = JSON.parse(sessionStorage.getItem('activeCurrency'));
                if (!savedActiveCurrency && data.length > 0) {
                    dispatch(setActiveCurrency(data[0]));
                    sessionStorage.setItem('activeCurrency', JSON.stringify(data[0]));
                }
            }
        } catch (err) {
            console.error('Ошибка при получении данных валют:', err);
        }
    };

    useEffect(() => {
        const savedCurrencies = JSON.parse(sessionStorage.getItem('currencies'));
        const savedActiveCurrency = JSON.parse(sessionStorage.getItem('activeCurrency'));

        // Устанавливаем сохраненные валюты и активную валюту
        if (savedCurrencies && savedCurrencies.length > 0) {
            dispatch(setCurrencies(savedCurrencies));
        } else {
            // Загружаем валюты с сервера, если они не сохранены
            fetchCurrencies();
        }

        // Устанавливаем сохраненную активную валюту, если она есть
        if (savedActiveCurrency) {
            dispatch(setActiveCurrency(savedActiveCurrency));
        }

        // Устанавливаем интервал для обновления курсов валют каждые 2 часа
        const intervalId = setInterval(fetchCurrencies, 2 * 60 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, [dispatch, getCurrency]);

    return (
        <HashRouter>
            <ScrollToTop />
            <AuthProvider>
                <PopupProvider>
                    <BasketProvider>
                        <CartProvider>
                            {/*<Disclaimer />*/}
                            <Header />
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/cart" element={<Cart />} />
                                <Route path="/catalog" element={<Catalog />} />
                                <Route path="/games" element={<CatalogGames />} />
                                <Route path="/subscribes" element={<Subscribes />} />
                                <Route path="/discounts" element={<Discounts />} />
                                <Route path="/catalog/:id" element={<GameInfo />} />
                                <Route path="/categories" element={<Categories />} />
                                <Route path="/edit-profile" element={<EditProfile />} />
                                <Route path="/favourites" element={<Favourites />} />
                                <Route path="/history" element={<History />} />
                                <Route path="/news-info" element={<NewsInfo />} />
                                <Route path="/news" element={<News />} />
                                <Route path="/payment" element={<Payment />} />
                                <Route path="/payment-talk" element={<PaymentTalk />} />
                                <Route path="/policy" element={<Policy />} />
                                <Route path="/profile" element={<ProfilePage />} />
                                <Route path="/support" element={<Support />} />
                                <Route path="/user-agreement" element={<UserAgreement />} />
                                <Route path="/public-offer" element={<PublicOffer />} />
                                <Route path="/payment-instruction" element={<PaymentInstruction />} />
                                <Route path="/menu" element={<MenuProfile />} />
                                <Route path="/steam" element={<Steam />} />
                            </Routes>
                            <Footer />
                        </CartProvider>
                    </BasketProvider>
                </PopupProvider>
            </AuthProvider>
        </HashRouter>
    )
}

export default App;