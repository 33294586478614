import { useTranslation } from "react-i18next";
import {useEffect} from "react";


const Policy = () => {
    const {t} = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0); // Прокрутка страницы на верх при загрузке компонента
    }, []);
    return (
        <div className="main">
            <div className="information">
                <div className="information__row">
                    <div className="news-title">{t('policy-header')}</div>
                    <div className="news-title-2">{t('policy-llp')}</div>
                    <div class="main-text">{t('policy-title')}</div>
                    <div class="news-title-2">{t('policy1')}</div>
                    <div class="main-text">{t('policy11')}</div>
                    <div class="main-text">{t('policy12')}</div>
                    <div class="main-text">{t('policy13')}</div>
                    <div class="news-title-2 space">{t('policy2')}</div>
                    <div class="main-text">{t('policy21')}</div>
                    <div class="main-text">{t('policy22')}</div>
                    <div class="main-text">{t('policy22-name')}</div>
                    <div class="main-text">{t('policy22-number')}</div>
                    <div class="main-text">{t('policy22-email')}</div>
                    <div class="main-text">{t('policy22-card')}</div>
                    <div class="main-text">{t('policy22-other')}</div>
                    <div class="news-title-2 space">{t('policy3')}</div>
                    <div class="main-text">{t('policy31')}</div>
                    <div class="main-text">{t('policy31-1')}</div>
                    <div class="main-text">{t('policy31-2')}</div>
                    <div class="main-text">{t('policy31-3')}</div>
                    <div class="main-text">{t('policy31-4')}</div>
                    <div class="main-text">{t('policy31-5')}</div>
                    <div class="main-text">{t('policy31-6')}</div>
                    <div class="news-title-2 space">{t('policy4')}</div>
                    <div class="main-text">{t('policy41')}</div>
                    <div class="main-text">{t('policy42')}</div>
                    <div class="news-title-2 space">{t('policy5')}</div>
                    <div class="main-text">{t('policy51')}</div>
                    <div class="main-text">{t('policy52')}</div>
                    <div class="main-text">{t('policy53')}</div>
                    <div class="news-title-2 space">{t('policy6')}</div>
                    <div class="main-text">{t('policy61')}</div>
                    <div class="main-text">{t('policy62')}</div>
                    <div className="news-title-2 space">{t('policy7')}</div>
                    <div class="main-text">{t('policy71')}</div>
                    <div class="main-text">{t('policy72')}</div>
                    <div className="news-title-2 space">{t('policy8')}</div>
                    <div class="main-text">{t('policy81')}</div>
                    <div class="main-text">{t('policy82')}</div>
                    <div class="main-text">{t('policy83')}</div>
                    <div className="news-title-2 space">{t('policy9')}</div>
                    <div class="main-text">{t('policy91')}</div>
                    <div class="main-text">{t('policy92')}</div>
                    <div class="main-text">{t('policy93')}</div>
                    <div class="main-text">{t('policy94')}</div>
                    <div class="main-text">{t('policy95')}</div>
                    <div class="main-text">{t('policy96')}</div>
                    <div class="main-text">{t('policy97')}</div>
                </div>
            </div>
        </div>
    );
}

export default Policy;