import Blank from "../components/blank/Blank";
import GameCard from "../components/gameCard/GameCard";
import Games from "../components/games/Games";
import { TemporaryBanner } from "../images/backgrounds";
import "../scss/news.scss";
import "../scss/game-card.scss";
import { useParams } from "react-router-dom";
import useMainService from "../services/MainService";
import { useEffect, useMemo, useRef, useState } from "react";
import Tags from "../components/tags/Tags";
import { useTranslation } from "react-i18next";

const GameInfo = () => {
    const [images, setImages] = useState([TemporaryBanner]);
    const { id } = useParams();
    const { getProduct } = useMainService();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const ref = useRef(null);
    // const pathValues = useMemo(() => ["Главная", "Каталог", (product ? product.gameName : "")], [product]);
    const {t} = useTranslation();

    const scrollToTop = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    useEffect(() => {
        scrollToTop();
        setLoading(true);
        getProduct(id)
            .then(onProductLoad)
            .catch(log => console.log(log));
    }, [id]);

    useEffect(() => {
        if (product)
            setImages(product.images.imagePaths.filter((item, index) => (index != 0)));
    }, [product]);

    const onProductLoad = (prod) => {
        setLoading(false);
        setProduct(prod);
    }

    const blank = useMemo(() =>
    (<>
        <Blank name={product ? product.name : ""} type={product ? product.typeName : ""} images={images} />
        {product ?
            <Tags desc={product.description} instruction={product.instruction} id={product.id} />
            :
            <Tags desc={""} instruction={""} />}
    </>),
        [product, loading, images]);

    return (
        <div className="content" ref={ref}>
            <div className="content__container start">
                {/* <Path values={pathValues} icon={RightLow} /> */}

                {blank}
                <Games title={t("new-games")}
                    filter={{ pageNumber: 6 }}
                    noButton
                    render={(index, name, price, oldPrice, id, imagePath) => { return <GameCard key={index} name={name} price={price} oldPrice={oldPrice} id={id} src={imagePath} /> }} >
                </Games>
            </div>
        </div>
    );
}

export default GameInfo;