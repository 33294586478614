import { useEffect, useMemo, useState } from "react";
import GameLong from "../components/gameLong/GameLong"
import Profile from "../components/profile/Profile";
import { EldenRing, UserAvatar } from "../images/backgrounds"
import useMainService from "../services/MainService";
import { useTranslation } from "react-i18next";

const ProfilePage = (props) => {
    const { getAllBaskets, getMe } = useMainService()
    const [baskets, setBaskets] = useState([]);
    const [user, setUser] = useState(null)

    useEffect(() => {
        getAllBaskets()
            .then(data => {
                setBaskets(data);
            })
            .catch(err => console.log(err));

        getMe()
            .then(data => {setUser(data); console.log(data);})
            .catch(err => console.log(err));
    }, []);

    const avatar = useMemo(() => (<Profile src={user ? user.imageUrl ? user.imageUrl : UserAvatar : UserAvatar} fullName={user ? `${user.userName}` : 'Benedict Camberbatch'} />))
    const {t} = useTranslation();

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleString('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    return (
        <main className="content">
            <div className="content__container">
                {avatar}

                <div className="nav-line ">
                    {/*<div className="nav-line__text">{t('games')} (7)</div>*/}
                </div>

                <div className="flow">
                    <div className="table">
                        <div className="table__head">
                            <div className="table__elem">{t('order-id')}</div>
                            <div className="table__elem">{t('order-name')}</div>
                            <div className="table__elem">{t('order-key')}</div>
                            <div className="table__elem">{t('order-date')}</div>
                        </div>
                        {baskets.map((basket, indexB) => {
                            return basket.basketItems.map((item, indexI) => {
                                return item.orders.map((order, indexO) => {
                                    return <div key={order.id} className="table__row">
                                        <div className="table__elem">{order.id}</div>
                                        <div className="table__elem">{item.name}</div>
                                        <div className="table__elem">{order.productKey}</div>
                                        <div className="table__elem">{formatDate(basket.createDate)}</div>
                                    </div>
                                })
                            })
                        })}
                    </div>
                </div>

            </div>
        </main>
    );
}

export default ProfilePage;