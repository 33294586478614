import EldenRing from "../images/backgrounds/elden-ring-game-card.webp";
import GameForm from "../components/gameForm/GameForm";
import "../scss/catalog.scss";
import CategoriesList from "../components/categoriesList/CategoriesList";
import {useLocation} from "react-router-dom";
import useMainService from "../services/MainService";
import {useEffect, useMemo, useState} from "react";
import {Pubg} from "../images/backgrounds";
import {useTranslation} from "react-i18next";
import {RotatingLines} from "react-loader-spinner";

const Catalog = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const name = searchParams.get('name');
    const category = searchParams.get('categoryname');

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortState, setSortState] = useState(0);
    const {getAllGames, getAllGamesByName} = useMainService();
    const {t} = useTranslation();

    useEffect(() => {
        setLoading(true);
        if (name) {
            getAllGamesByName(name).then(onProductLoad).catch(err => {
                console.log(err);
                setLoading(false);
            });
        } else {
            getAllGames().then(onProductLoad).catch(err => {
                console.log(err);
                setLoading(false);
            });
        }
    }, [category, name, sortState, currentPage])

    const onProductLoad = (prods) => {
        setLoading(false);
        setProducts(prods);
    }

    const elems = [
        {value: "По убыванию цены", state: 1},
        {value: "По возрастанию цены", state: 2}
    ];

    const children = useMemo(() => (products
        // .filter(item => item.isActive === false)
        .map((item, index) =>
            (<GameForm
                key={item.id}
                src={item.images ? item.images.mainframe ? item.images.mainframe : EldenRing : Pubg}
                id={item.id}
                name={item.name}
                extraClass={"videogames__item"}
                indicators={[]}/>))), [products]);

    const showMoreGames = () => {
        setCurrentPage(currentPage => (currentPage + 1));
    }



    // const showMoreBtn = useMemo(() => (
    //     currentPage * pageSize < totalSize ? <div className="sides__field">
    //         <Button extra={"btn-strong"} text={"Показать еще"} onClick={showMoreGames} />
    //     </div> : null
    // ), [products]);

    return (
        <>
            <div className="main">
                <CategoriesList/>

                <div className="main__container">
                    {/*<div className="filter">*/}
                    {/*    <div className="filter__row">*/}
                    {/*        <div*/}
                    {/*            className="filter__title title">{category ? category : name ? name : t('catalog-link')}</div>*/}
                    {/*        <FilterSort*/}
                    {/*            classes={"filter__sort"}*/}
                    {/*            values={elems}*/}
                    {/*            setSortState={setSortState}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="sides">
                        <div className="sides__row">
                            <div className="sides__container">
                                <div className="videogames">
                                    <div className="videogames__row">
                                        {loading ? (  // Показываем спиннер во время загрузки
                                            <div style={{margin:"0 auto"}}>
                                                <RotatingLines
                                                    strokeColor="grey"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="96"
                                                    visible={true}
                                                />
                                            </div>
                                        ) : (
                                            children
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Catalog;